<template>
  <div>
    <!-- #page-header -->
    <div
      id="page-header"
      class="cover -small"
      data-image-src="assets/img/bg/kontakt.jpg"
    >
      <div class="container">
        <div class="-wrapper">
          <div class="_inner">
            <h1 v-translate>Registračný formulár partnera</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- /#page-header -->

    <!-- .section -->
    <section class="section">
      <div class="container">
        <!-- .form -->
        <form class="form" @submit.prevent="submitForm">
          <div class="form-block">
            <h2 v-translate>
              Vyplňte prosím tieto základné údaje potrebné pre Vašu registráciu:
            </h2>

            <div class="row">
              <div class="col-md-6">
                <label v-translate>Názov firmy / Meno</label>
                <input
                  type="text"
                  name="company"
                  v-model="data.company"
                  v-validate="{ required: true }"
                />
                <span class="reg_error">{{ errors.first("company") }}</span>
              </div>
              <div class="col-md-3">
                <label v-translate>IČO</label>
                <input
                  type="text"
                  name="ico"
                  v-model="data.ico"
                  v-validate="{
                    required: true,
                    numeric: this.data.country.value == 'SK',
                  }"
                />
                <span class="reg_error">{{ errors.first("ico") }}</span>
                <!-- ICO iba ciselne pre SK stat -->
              </div>
              <div class="col-md-3">
                <label v-translate>DIČ</label>
                <input
                  type="text"
                  name="dic"
                  v-model="data.dic"
                  v-validate="{ required: this.data.country.value == 'SK' }"
                />
                <span class="reg_error">{{ errors.first("dic") }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <label v-translate>Ulica</label>
                <input
                  type="text"
                  name="street"
                  v-model="data.street"
                  v-validate="{ required: true }"
                />
                <span class="reg_error">{{ errors.first("street") }}</span>
              </div>
              <div class="col-md-3">
                <label v-translate>Štát</label>
                <v-select
                  name="country"
                  v-validate="{ required: true }"
                  v-model="data.country"
                  :options="[
                    { label: 'Slovenská republika', value: 'SK' },
                    { label: 'Belgicko', value: 'BE' },
                    { label: 'Nemecko', value: 'DE' },
                    { label: 'Francúzsko', value: 'FR' },
                    { label: 'Taliansko', value: 'IT' },
                    { label: 'Luxembursko', value: 'LU' },
                    { label: 'Holandsko', value: 'NL' },
                    { label: 'Dánsko', value: 'DK' },
                    { label: 'Írsko', value: 'IE' },
                    { label: 'Spojené Kráľovstvo', value: 'GB' },
                    { label: 'Grécko', value: 'GR' },
                    { label: 'Španielsko', value: 'ES' },
                    { label: 'Portugalsko', value: 'PT' },
                    { label: 'Rakúsko', value: 'AT' },
                    { label: 'Fínsko', value: 'FI' },
                    { label: 'Švédsko', value: 'SE' },
                    { label: 'Česká republika', value: 'CZ' },
                    { label: 'Poľsko', value: 'PL' },
                    { label: 'Lotyšsko', value: 'LV' },
                    { label: 'Maďarsko', value: 'HU' },
                    { label: 'Litva', value: 'LT' },
                    { label: 'Malta', value: 'MT' },
                    { label: 'Estónsko', value: 'EE' },
                    { label: 'Slovinsko', value: 'SI' },
                    { label: 'Cyprus', value: 'CY' },
                    { label: 'Bulharsko', value: 'BG' },
                    { label: 'Rumunsko', value: 'RO' },
                    { label: 'Surinam', value: 'SR' },
                    { label: 'Dominikánska republika', value: 'DO' },
                    { label: 'Samoa', value: 'WS' },
                    { label: 'Spojené Štáty Americké', value: 'US' },
                    { label: 'Kanada', value: 'CA' },
                    { label: 'Argentína', value: 'AR' },
                    { label: 'Azerbajdžan', value: 'AZ' },
                    { label: 'Eritrea', value: 'ER' },
                    { label: 'Panama', value: 'PA' },
                    { label: 'Rusko', value: 'RU' },
                    { label: 'Ukrajina', value: 'UA' },
                    { label: 'Čína', value: 'CN' },
                    { label: 'India', value: 'IN' },
                    { label: 'Indonézia', value: 'ID' },
                    { label: 'Mexiko', value: 'MX' },
                    { label: 'Turecko', value: 'TR' },
                    { label: 'Bielorusko', value: 'BY' },
                    { label: 'Seyšely', value: 'SC' },
                    { label: 'Chorvátsko', value: 'HR' },
                    { label: 'Tuvalu', value: 'TV' },
                    { label: 'Tongo', value: 'TO' },
                    { label: 'Singapur', value: 'SG' },
                    { label: 'Moldavsko', value: 'MD' },
                    { label: 'Lichtenštajnsko', value: 'LI' },
                    { label: 'Izrael', value: 'IL' },
                    { label: 'Spojené Arabské Emiráty', value: 'AE' },
                    { label: 'Arménsko', value: 'AM' },
                    { label: 'Americká Samoa', value: 'AS' },
                    { label: 'Kokosové ostrovy', value: 'CC' },
                    { label: 'Švajčiarsko', value: 'CH' },
                    { label: 'Južná Afrika', value: 'ZA' },
                    { label: 'Brazília', value: 'BR' },
                    { label: 'Ostrov Man', value: 'IM' },
                    { label: 'Jersey', value: 'JE' },
                    { label: 'Japonsko', value: 'JP' },
                    { label: 'Čína-Taiwan', value: 'TW' },
                    { label: 'Hong Kong', value: 'HK' },
                    { label: 'Nový Zéland', value: 'NZ' },
                    { label: 'Čierna Hora', value: 'ME' },
                    { label: 'Kazachstan', value: 'KZ' },
                    { label: 'Uzbekistan', value: 'UZ' },
                    { label: 'Thajsko', value: 'TH' },
                    { label: 'Austrália', value: 'AU' },
                    { label: 'Nórsko', value: 'NO' },
                    { label: 'Bosna a Hercegovina', value: 'BA' },
                    { label: 'Kolumbia', value: 'CO' },
                    { label: 'Grónsko', value: 'GL' },
                    { label: 'Svätá Lucia', value: 'LC' },
                    { label: 'Maroko', value: 'MA' },
                    { label: 'Malajzia', value: 'MY' },
                    { label: 'Peru', value: 'PE' },
                    { label: 'Pakistan', value: 'PK' },
                    { label: 'Sierra Leone', value: 'SL' },
                    { label: 'Somálsko', value: 'SO' },
                    { label: 'Vietnam', value: 'VN' },
                    { label: 'Afganistan', value: 'AF' },
                    { label: 'Albánsko', value: 'AL' },
                    { label: 'Alžírsko', value: 'DZ' },
                    { label: 'Andorra', value: 'AD' },
                    { label: 'Angola', value: 'AO' },
                    { label: 'Antigua a Barbuda', value: 'AG' },
                    { label: 'Bahamy', value: 'BS' },
                    { label: 'Bahrajn', value: 'BH' },
                    { label: 'Bangladéš', value: 'BD' },
                    { label: 'Barbados', value: 'BB' },
                    { label: 'Belize', value: 'BZ' },
                    { label: 'Benin', value: 'BJ' },
                    { label: 'Bhután', value: 'BT' },
                    { label: 'Bolívia', value: 'BO' },
                    { label: 'Botswana', value: 'BW' },
                    { label: 'Brunej', value: 'BN' },
                    { label: 'Burkina', value: 'BF' },
                    { label: 'Burundi', value: 'BI' },
                    { label: 'Čad', value: 'TD' },
                    { label: 'Čile', value: 'CL' },
                    { label: 'Dominika', value: 'DM' },
                    { label: 'Džibutsko', value: 'DJ' },
                    { label: 'Egypt', value: 'EG' },
                    { label: 'Ekvádor', value: 'EC' },
                    { label: 'Etiópia', value: 'ET' },
                    { label: 'Fidži', value: 'FJ' },
                    { label: 'Filipíny', value: 'PH' },
                    { label: 'Gabon', value: 'GA' },
                    { label: 'Gambia', value: 'GM' },
                    { label: 'Ghana', value: 'GH' },
                    { label: 'Grenada', value: 'GD' },
                    { label: 'Gruzínsko', value: 'GE' },
                    { label: 'Guatemala', value: 'GT' },
                    { label: 'Guinea', value: 'GN' },
                    { label: 'Guinea-Bissau', value: 'GW' },
                    { label: 'Guyana', value: 'GY' },
                    { label: 'Haiti', value: 'HT' },
                    { label: 'Honduras', value: 'HN' },
                    { label: 'Irak', value: 'IQ' },
                    { label: 'Irán', value: 'IR' },
                    { label: 'Island', value: 'IS' },
                    { label: 'Jamajka', value: 'JM' },
                    { label: 'Jemen', value: 'YE' },
                    { label: 'Jordánsko', value: 'JO' },
                    { label: 'Južný Sudán', value: 'SS' },
                    { label: 'Kambodža', value: 'KH' },
                    { label: 'Kamerun', value: 'CM' },
                    { label: 'Kapverdy', value: 'CV' },
                    { label: 'Katar', value: 'QA' },
                    { label: 'Kena', value: 'KE' },
                    { label: 'Kirgizsko', value: 'KG' },
                    { label: 'Kiribati', value: 'KI' },
                    { label: 'Komory', value: 'KM' },
                    { label: 'Kongo', value: 'CG' },
                    { label: 'Konžská demokratická republika', value: 'CD' },
                    {
                      label: 'Kórejská ľudovodemokratická republika',
                      value: 'KP',
                    },
                    { label: 'Kórejská republika', value: 'KR' },
                    { label: 'Kostarika', value: 'CR' },
                    { label: 'Kuba', value: 'CU' },
                    { label: 'Kuvajt', value: 'KW' },
                    { label: 'Laos', value: 'LA' },
                    { label: 'Lesotho', value: 'LS' },
                    { label: 'Libanon', value: 'LB' },
                    { label: 'Libéria', value: 'LR' },
                    { label: 'Líbya', value: 'LY' },
                    { label: 'Macedónska republika', value: 'MK' },
                    { label: 'Madagaskar', value: 'MG' },
                    { label: 'Malawi', value: 'MW' },
                    { label: 'Maldivy', value: 'MV' },
                    { label: 'Mali', value: 'ML' },
                    { label: 'Marshallove ostrovy', value: 'MH' },
                    { label: 'Maurícius', value: 'MU' },
                    { label: 'Mauritánia', value: 'MR' },
                    { label: 'Mikronézia', value: 'FM' },
                    { label: 'Mjanmarsko', value: 'MM' },
                    { label: 'Monako', value: 'MC' },
                    { label: 'Mongolsko', value: 'MN' },
                    { label: 'Mozambik', value: 'MZ' },
                    { label: 'Namíbia', value: 'NA' },
                    { label: 'Nauru', value: 'NR' },
                    { label: 'Nepál', value: 'NP' },
                    { label: 'Niger', value: 'NE' },
                    { label: 'Nigéria', value: 'NG' },
                    { label: 'Nikaragua', value: 'NI' },
                    { label: 'Omán', value: 'OM' },
                    { label: 'Palau', value: 'PW' },
                    { label: 'Palestína', value: 'PS' },
                    { label: 'Papua-Nová Guinea', value: 'PG' },
                    { label: 'Paraguaj', value: 'PY' },
                    { label: 'Pobrežie Slonoviny', value: 'CI' },
                    { label: 'Rovníková Guinea', value: 'GQ' },
                    { label: 'Rwanda', value: 'RW' },
                    { label: 'Salvádor', value: 'SV' },
                    { label: 'San Maríno', value: 'SM' },
                    { label: 'Saudská Arábia', value: 'SA' },
                    { label: 'Senegal', value: 'SN' },
                    { label: 'Srbsko', value: 'RS' },
                    { label: 'Srí Lanka', value: 'LK' },
                    { label: 'Stredoafrická republika', value: 'CF' },
                    { label: 'Sudán', value: 'SD' },
                    { label: 'Svazijsko', value: 'SZ' },
                    { label: 'Svätý Krištof a Nevis', value: 'KN' },
                    { label: 'Svätý Tomáš a Princov ostrov', value: 'ST' },
                    { label: 'Svätý Vincent a Grenadíny', value: 'VC' },
                    { label: 'Sýria', value: 'SY' },
                    { label: 'Šalamúnove ostrovy', value: 'SB' },
                    { label: 'Tadžikistan', value: 'TJ' },
                    { label: 'Tanzánia', value: 'TZ' },
                    { label: 'Togo', value: 'TG' },
                    { label: 'Trinidad a Tobago', value: 'TT' },
                    { label: 'Tunisko', value: 'TN' },
                    { label: 'Turkménsko', value: 'TM' },
                    { label: 'Uganda', value: 'UG' },
                    { label: 'Uruguaj', value: 'UY' },
                    { label: 'Vanuatu', value: 'VU' },
                    { label: 'Vatikán', value: 'VA' },
                    { label: 'Venezuela', value: 'VE' },
                    { label: 'Východný Timor', value: 'TL' },
                    { label: 'Zambia', value: 'ZM' },
                    { label: 'Západná Sahara', value: 'EH' },
                    { label: 'Zimbabwe', value: 'ZW' },
                    { label: 'Curacao', value: 'CW' },
                    { label: 'Guernsey', value: 'GG' },
                    { label: 'Réunion', value: 'RE' },
                    { label: 'Macao', value: 'MO' },
                    { label: 'Americké Panenské ostrovy', value: 'VI' },
                    { label: 'Aruba', value: 'AW' },
                    { label: 'Guam', value: 'GU' },
                    { label: 'Severné Mariány', value: 'MP' },
                    { label: 'Britské Panenské ostrovy', value: 'VG' },
                    { label: 'Bermudy', value: 'BM' },
                    { label: 'Portoriko', value: 'PR' },
                  ]"
                >
                  <span slot="no-options" v-translate>Žiadna zhoda</span>
                </v-select>
                <span class="reg_error">{{ errors.first("country") }}</span>
              </div>
              <div class="col-md-3">
                <label v-translate>IČ DPH</label>
                <input type="text" name="ic_dph" v-model="data.ic_dph" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <label v-translate>Mesto</label>
                <input
                  type="text"
                  name="town"
                  v-model="data.town"
                  v-validate="{ required: true }"
                />
                <span class="reg_error">{{ errors.first("town") }}</span>
              </div>
              <div class="col-md-3">
                <label v-translate>PSČ</label>
                <input
                  type="text"
                  name="postcode"
                  v-model="data.postcode"
                  v-validate="{ required: true }"
                />
                <span class="reg_error">{{ errors.first("postcode") }}</span>
              </div>
              <div class="col-md-3">
                <label v-translate>Fakturačný jazyk</label>
                <v-select
                  name="select_language"
                  v-validate="{ required: true }"
                  v-model="data.select_language"
                  :searchable="false"
                  :options="[
                    { label: 'slovenčina', value: 'sk' },
                    { label: 'angličtina', value: 'en' },
                  ]"
                >
                </v-select>
                <span class="reg_error">{{
                  errors.first("select_language")
                }}</span>
              </div>
            </div>
          </div>

          <div class="form-block">
            <h2 v-translate>Vyplňte prosím kontaktné údaje:</h2>

            <div class="row">
              <div class="col-md-6">
                <label v-translate>Meno kontaktnej osoby</label>
                <input
                  type="text"
                  name="contact"
                  v-model="data.contact"
                  v-validate="{ required: true }"
                />
                <span class="reg_error">{{ errors.first("contact") }}</span>
              </div>
              <div class="col-md-3">
                <label v-translate>E-mail</label>
                <input
                  type="email"
                  name="email"
                  v-model="data.email"
                  v-validate="{ required: true, email: true }"
                />
                <span class="reg_error">{{ errors.first("email") }}</span>
              </div>
              <div class="col-md-3">
                <label v-translate>Tel. číslo</label>
                <input
                  type="tel"
                  name="phone"
                  v-model="data.phone"
                  v-validate="{ required: true }"
                />
                <span class="reg_error">{{ errors.first("phone") }}</span>
              </div>
            </div>
          </div>

          <div class="form-block">
            <h2 v-translate>Zvoľte si heslo pre prístup do systému:</h2>

            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6">
                    <label v-translate>Heslo:</label>
                    <input
                      type="password"
                      name="password"
                      v-model="data.password"
                      v-validate="{
                        required: true,
                        regex:
                          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,255}$/,
                      }"
                    />
                    <span class="reg_error">{{
                      errors.first("password")
                    }}</span>
                  </div>
                  <div class="col-md-6">
                    <label v-translate>Zadajte heslo znovu pre kontrolu</label>
                    <input
                      type="password"
                      name="rpassword"
                      v-model="data.rpassword"
                      v-validate="{ required: true, is: data.password }"
                    />
                    <span class="reg_error">{{
                      errors.first("rpassword")
                    }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="checkboxes">
                      <div class="checkbox">
                        <input
                          type="checkbox"
                          name="tnc"
                          v-model="data.tnc"
                          v-validate="{ required: true }"
                          id="ch01"
                        />
                        <label for="ch01"
                          ><span v-translate>Súhlasím s </span>
                          <router-link :to="{ name: 'terms' }" v-translate
                            >obchodnými podmienkami</router-link
                          >
                          <span v-translate> Webpartner.sk</span></label
                        >
                        <span class="reg_error">{{ errors.first("tnc") }}</span>
                      </div>

                      <div class="checkbox">
                        <input
                          type="checkbox"
                          name="agree_2"
                          v-model="data.agree_2"
                          v-validate="{ required: true }"
                          id="ch02"
                        />
                        <label for="ch02"
                          ><span v-translate>Súhlasím so </span
                          ><a
                            target="blank"
                            href="https://www.webglobe.sk/upload/700-zasady-ochrany-osobnych-udajov.pdf"
                            v-translate
                            >spracovaním osobných údajov</a
                          ></label
                        >
                        <span class="reg_error">{{
                          errors.first("agree_2")
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <input
                      class="alt"
                      type="submit"
                      v-bind:value="$gettext('odoslať formulár')"
                    />
                    <span
                      class="reg_error"
                      v-translate
                      v-if="data.error_form_sending"
                      >Formulár sa nepodarilo odoslať</span
                    >
                    <span
                      class="reg_error"
                      v-translate
                      v-if="data.error_form_validating"
                      >Formulár sa nepodarilo validovať</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <!--<img src="assets/img/bg/referencie-webpartner.png" alt="Testimonials">-->
              </div>
            </div>
          </div>
        </form>
        <!-- /.form -->
      </div>
    </section>
    <!-- /.section -->

    <section class="section -grey">
      <div class="container">
        <!-- .partners -->
        <div class="partners noOffset">
          <h3 v-translate>
            Na technologickiej infraštruktúre od WY budú vaši klienti v skvelých
            rukách. Našim službám a produktom dôverujú aj:
          </h3>

          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <!-- .partner -->
                <div class="partner">
                  <div class="_wrapper">
                    <img src="assets/img/logos/ambitas.png" alt="" />
                  </div>
                </div>
                <!-- /.partner -->
              </div>
              <div class="swiper-slide">
                <!-- .partner -->
                <div class="partner">
                  <div class="_wrapper">
                    <img src="assets/img/logos/pravdask.png" alt="" />
                  </div>
                </div>
                <!-- /.partner -->
              </div>
              <div class="swiper-slide">
                <!-- .partner -->
                <div class="partner">
                  <div class="_wrapper">
                    <img src="assets/img/logos/radioexpres.png" alt="" />
                  </div>
                </div>
                <!-- /.partner -->
              </div>
              <div class="swiper-slide">
                <!-- .partner -->
                <div class="partner">
                  <div class="_wrapper">
                    <img src="assets/img/logos/techbox.png" alt="" />
                  </div>
                </div>
                <!-- /.partner -->
              </div>
              <div class="swiper-slide">
                <!-- .partner -->
                <div class="partner">
                  <div class="_wrapper">
                    <img src="assets/img/logos/galton.svg" alt="" />
                  </div>
                </div>
                <!-- /.partner -->
              </div>
            </div>
          </div>
        </div>
        <!-- /.partners -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "registration",
  metaInfo() {
    return {
      title: this.$gettext("registration_title"),
      titleTemplate: "%s | Webpartner",
      htmlAttrs: {
        lang: "sk-SK",
        amp: undefined,
      },
      meta: [{ name: "ROBOTS", content: "NOINDEX, NOFOLLOW" }],
      link: [{ rel: "canonical", href: "https://webpartner.sk/registracia" }],
    };
  },
  data() {
    return {
      data: {
        billfirm: "WEBG",
        country: { label: "Slovenská republika", value: "SK" },
        select_language: { label: "slovenčina", value: "sk" },
        error_form_sending: false,
        error_form_validating: false,
      },
    };
  },
  mounted: function () {
    const dictionary = {
      en: {
        messages: {
          required: this.$gettext("validator_required"),
          is: this.$gettext("validator_is_same"),
          email: this.$gettext("validator_email"),
          regex: this.$gettext("validator_regex"),
          numeric: this.$gettext("validator_numeric"),
        },
        custom: {
          tnc: {
            required: this.$gettext("validator_agree_1"),
          },
          agree_2: {
            required: this.$gettext("validator_agree_2"),
          },
        },
      },
    };
    this.$validator.localize(dictionary);
  },
  methods: {
    submitForm: function () {
      const axios = require("axios");
      this.$validator
        .validateAll()
        .then((result) => {
          if (result) {
            this.data.state = this.data.country.value;
            this.data.language = this.data.select_language.value;
            axios
              .post("https://api.controlpanel.sk/wp-register", this.data)
              .then((response) => {
                if (response.data.success == true) {
                  this.data.error_form_sending = false;
                  this.$router.push({ name: "registration-sent" });
                } else {
                  this.data.error_form_sending = true;
                }
              })
              .catch(() => {
                this.data.error_form_sending = true;
              });
          }
        })
        .catch(() => {
          this.data.error_form_validating = true;
        });
    },
  },
};
</script>
